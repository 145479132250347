<template>
  <Component
    :is="tag"
    :class="classes"
    :data-grade="grade"
  >
    <div class="answer__body">
      <slot>
        <template v-if="answer.isRankingType">
          <span class="answer__index">{{ index + 1 }}</span>
          <p class="answer__text">
            {{ answer.answer }}
          </p>
        </template>

        <p
          v-else
          class="answer__text"
        >
          {{ answer.answer }}
        </p>
      </slot>
    </div>

    <UComment
      v-if="isRevealed && answer.comments"
      class="mt-1"
    >
      {{ answer.comments }}
    </UComment>
  </Component>
</template>

<script>
import UComment from '@components/UComment.vue'

export default {
  name: 'TrainingQuizAnswer',

  components: {
    UComment
  },

  props: {
    answer: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      default: 0
    },

    isRevealed: {
      type: Boolean,
      default: false
    },

    isReadonly: {
      type: Boolean,
      default: false
    },

    isShowCorrectAnswer: {
      type: Boolean,
      default: false
    },

    tag: {
      type: String,
      default: 'div'
    }
  },

  computed: {
    grade () {
      const MAX_GRADE_VALUE = 100
      const MIN_GRADE_VALUE = 0

      if (!this.isRevealed) {
        return
      }

      if (this.answer.isRankingType) {
        return (this.index + 1) === parseInt(this.answer.settings.key) ? MAX_GRADE_VALUE : MIN_GRADE_VALUE
      }

      return Math.max(parseInt(this.answer.grade), MIN_GRADE_VALUE)
    },

    classes () {
      return {
        answer: true,
        'answer--type-simple': this.answer.isSimpleType,
        'answer--type-ranking': this.answer.isRankingType,
        'answer--selected': this.answer.isSelected,
        'answer--revealed': this.isRevealed,
        'answer--readonly': this.isReadonly
      }
    }
  }
}
</script>

<style lang="scss">
  .answer {
    border-radius: $border-radius-root;
    padding: 0;
    margin: 0;

    .v-label {
      user-select: inherit;
    }
  }

  .answer--type-ranking:not(.answer--readonly) {
    margin: 0.5rem 0;
    border: $border;
    cursor: move;
  }

  .answer--readonly {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }

  .answer--selected.answer--readonly {
    background-color: rgba(black, 0.06);
  }

  .answer--revealed {
    &[data-grade],
    &[data-grade] .v-label {
      color: var(--v-warning-base);
    }

    &[data-grade="0"],
    &[data-grade^="-"],
    &[data-grade="0"] .v-label,
    &[data-grade^="-"] .v-label {
      color: var(--v-error-base);
    }

    &[data-grade="100"],
    &[data-grade="100"] .v-label {
      color: var(--v-success-base);
    }
  }

  .answer__body {
    display: flex;
    gap: 1rem;
    position: relative;
    padding: 0;
  }

  .answer__text.answer__text {
    margin: 0;
    padding: 0.5rem 0;
  }

  .answer__index {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: rgba(black, 0.12);
    font-weight: 600;
  }
</style>
