<template>
  <VCard class="step-quiz">
    <header class="step__header">
      <StepToolbar :step="quiz" />

      <VCardTitle class="lesson-title">
        {{ step.lesson_title }}
      </VCardTitle>

      <VCardText>
        <VRow>
          <VCol
            cols="12"
            lg="6"
          >
            <template v-if="quiz.is_completed">
              <VSheet
                class="mb-4"
                color="rgba(0, 0, 0, 0.12)"
              >
                <VMenu v-if="step.attempts.length > 1">
                  <template #activator="{ on, attrs }">
                    <VListItem
                      dense
                      title="Все попытки по этому уроку"
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      <VListItemIcon>
                        <VIcon>expand_more</VIcon>
                      </VListItemIcon>
                      <VListItemContent>
                        <VListItemTitle>
                          {{ $t('attempts.one') }}: <time :datetime="quiz.completed_at">{{ formatDateTime(quiz.completed_at) }}</time>
                        </VListItemTitle>
                      </VListItemContent>
                      <VListItemAction>
                        <VListItemActionText :class="`${getStatus(quiz).color}--text`">
                          {{ $t(getStatus(quiz).title) }}
                        </VListItemActionText>
                      </VListItemAction>
                    </VListItem>
                  </template>
                  <VList dense>
                    <template v-for="(attempt, idx) in step.attempts">
                      <VDivider
                        v-if="idx !== 0"
                        :key="`attempt${idx}`"
                      />
                      <VListItem
                        :key="attempt.id"
                        :value="attempt.id"
                        :to="{ query: { attempt: attempt.id } }"
                        exact
                      >
                        <VListItemContent>
                          <VListItemTitle>
                            {{ $t('attempts.one') }}:
                            <time :datetime="attempt.completed_at">{{ formatDateTime(attempt.completed_at) }}</time>
                          </VListItemTitle>
                        </VListItemContent>
                        <VListItemAction>
                          <VListItemActionText :class="`${getStatus(attempt).color}--text`">
                            {{ $t(getStatus(attempt).title) }}
                          </VListItemActionText>
                        </VListItemAction>
                      </VListItem>
                    </template>
                  </VList>
                </VMenu>
                <VListItem
                  v-else
                  dense
                >
                  <VListItemContent>
                    <VListItemTitle>
                      {{ $t('attempts.one') }}: <time :datetime="quiz.completed_at">{{ formatDateTime(quiz.completed_at) }}</time>
                    </VListItemTitle>
                  </VListItemContent>
                  <VListItemAction>
                    <VListItemActionText :class="`${getStatus(quiz).color}--text`">
                      {{ $t(getStatus(quiz).title) }}
                    </VListItemActionText>
                  </VListItemAction>
                </VListItem>
              </VSheet>

              <p>
                <label>{{ $t('time_to_attempt.one') }}: </label>
                <time>{{ spentTime }}</time>
              </p>

              <p
                v-if="quiz.is_accepted"
                class="success--text"
              >
                Вы набрали {{ formatInt(quiz.grade, ['балл', 'балла', 'баллов']) }}.
              </p>
              <template v-else-if="quiz.is_evaluated">
                <p
                  v-if="acceptableGrade"
                  class="error--text"
                >
                  {{ $t('points_count_of.one', {
                    count:quiz.grade,
                    all: quiz.settings.max_grade_per_quiz * (acceptableGrade.min / 100)
                  }) }}.
                </p>
                <p
                  v-else
                  class="error--text"
                >
                  {{ $t('points_count_of.one', {
                    count:quiz.grade,
                    all: quiz.settings.min_passing_grade
                  }) }}.
                </p>
              </template>

              <p v-if="quiz.mark">
                <label>{{ $t('marks.one') }}: </label>
                <span :class="[quiz.is_accepted ? 'success--text': 'error--text']">{{ quiz.mark }}</span>
              </p>
            </template>

            <template v-if="!step.is_accepted && (!quiz.is_started || quiz.is_evaluated)">
              <template v-if="quiz.settings.max_attempts_total > 0">
                <p v-if="step.attempts_left_total > 1">
                  {{ $t('attempts_left_total.one', {
                    count: step.attempts_left_total,
                    all: quiz.settings.max_attempts_total,
                    unit: selectUnit(quiz.settings.max_attempts_total, [
                      $t('attempts.genitive_one'),
                      $t('attempts.genitive'),
                      $t('attempts.genitive'),
                    ]).toLowerCase(),
                  }) }}
                </p>
                <p
                  v-else-if="step.attempts_left_total === 1"
                  class="warning--text"
                >
                  {{ $t('last_attempt_left_total.one') }}.
                </p>
                <p
                  v-else
                  class="error--text"
                >
                  {{ $t('no_attempts_left_total.one') }}.
                </p>
              </template>
              <template v-if="quiz.settings.max_attempts_per_day > 0">
                <p v-if="step.attempts_left_today > 1">
                  {{ $t('attempts_left_today.one', {
                    count: step.attempts_left_today,
                    all: quiz.settings.max_attempts_per_day,
                    unit: selectUnit(quiz.settings.max_attempts_per_day, [
                      $t('attempts.genitive_one'),
                      $t('attempts.genitive'),
                      $t('attempts.genitive'),
                    ]).toLowerCase(),
                  }) }}
                </p>
                <p
                  v-else-if="step.attempts_left_today === 1"
                  class="warning--text"
                >
                  {{ $t('last_attempt_left_today.one') }}.
                </p>
                <p
                  v-else
                  class="error--text"
                >
                  {{ $t('no_attempts_left_today.one') }}.
                </p>
              </template>
            </template>
          </VCol>

          <VCol
            cols="12"
            lg="6"
          >
            <p
              v-if="quiz.is_required"
              class="warning--text"
              small
            >
              {{ $t('required.one') }}!
            </p>
            <p
              v-else
              class="info--text"
              small
            >
              {{ $t('optional.one') }}.
            </p>
            <p
              v-if="quiz.is_exercise"
              class="success--text"
              small
            >
              {{ $t('exercise.one') }}.
            </p>

            <VAlert
              v-if="step.is_summative && isAvailableSummativeRaw"
              type="warning"
            >
              {{ isAvailableSummativeRaw }}
            </VAlert>

            <p v-if="quiz.settings.max_minutes_per_attempt > 0">
              {{ $t('$app.test_execution_time') }} <time>{{ formatInt(quiz.settings.max_minutes_per_attempt, [$t('minutes.accusative_one'), $t('minutes.accusative_two'), $t('minutes.accusative_many')]) }}</time>.
            </p>

            <template v-if="acceptableGrade">
              <p v-if="acceptableGrade.min > 0">
                <template v-if="quiz.settings.max_grade_per_quiz > 0">
                  {{ $t('positive_score_of.one', {
                    count: quiz.settings.max_grade_per_quiz * (acceptableGrade.min / 100),
                    all: quiz.settings.max_grade_per_quiz
                  }) }}.
                </template>
                <template v-else>
                  {{ $t('positive_score.one') }}: {{ quiz.settings.max_grade_per_quiz * (acceptableGrade.min / 100) }}.
                </template>
              </p>
            </template>

            <template v-else>
              <p v-if="quiz.settings.min_passing_grade > 0">
                <template v-if="quiz.settings.max_grade_per_quiz > 0">
                  {{ $t('positive_score_of.one', {
                    count: quiz.settings.min_passing_grade,
                    all: quiz.settings.max_grade_per_quiz
                  }) }}.
                </template>
                <template v-else>
                  {{ $t('positive_score.one') }}: {{ quiz.settings.min_passing_grade }}.
                </template>
              </p>
            </template>

            <p class="d-print-none">
              {{ $t('you_get_maximum_of_100_scores.one') }}.
            </p>

            <UPrintResults
              v-if="quiz.is_completed"
              :html="`/training/quizzes/${quiz.id}/results?_fmt=html`"
              :pdf="`/training/quizzes/${quiz.id}/results?_fmt=pdf`"
              :odt="`/training/quizzes/${quiz.id}/results?_fmt=odt`"
            />
          </VCol>
        </VRow>
      </VCardText>

      <VCardText
        v-if="lesson.settings.is_public_description"
        class="text--primay"
      >
        <div
          class="rte-content"
          v-html="lesson.description"
        />
      </VCardText>

      <VCardActions
        v-if="permissions.can_write && !session.is_completed"
        class="actions"
      >
        <template v-if="quiz.is_exercise && quiz.is_completed">
          <VBtn
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            :loading="isStarting"
            @click.prevent="start"
          >
            <VIcon left>
              replay
            </VIcon> {{ $t('again.one') }}
          </VBtn>
        </template>

        <template v-else-if="quiz.is_accepted">
          <VDialog
            v-if="!step.is_accepted && quiz.settings.accept_manually"
            v-model="dialog"
            persistent
            max-width="400"
          >
            <template #activator="{ on, attrs }">
              <VBtn
                :large="$vuetify.breakpoint.lgAndUp"
                v-bind="attrs"
                color="success"
                v-on="on"
              >
                <VIcon left>
                  done
                </VIcon> {{ $t('accept.one') }}
              </VBtn>
            </template>
            <VCard>
              <VCardTitle>Приняв результаты итогового тестирования, вы не сможете пройти текущую программу заново. Принять результаты?</VCardTitle>
              <VDivider />
              <VCardActions class="actions">
                <VSpacer />
                <VBtn
                  color="primary"
                  text
                  @click="dialog=false"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  color="success"
                  @click="accept"
                >
                  {{ $t('accept.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
          <VBtn
            v-if="!step.is_completed && step.attempts_left_total > 0 && step.attempts_left_today > 0"
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            :loading="isStarting"
            @click.prevent="start"
          >
            <VIcon left>
              replay
            </VIcon> {{ $t('again.one') }}
          </VBtn>
        </template>

        <template v-else-if="quiz.is_evaluated">
          <VBtn
            v-if="!step.is_completed && step.attempts_left_total > 0 && step.attempts_left_today > 0"
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            :loading="isStarting"
            @click.prevent="start"
          >
            <VIcon left>
              replay
            </VIcon> {{ $t('again.one') }}
          </VBtn>
        </template>

        <template v-else-if="quiz.is_completed">
          <!--  -->
        </template>

        <template v-else-if="quiz.is_started">
          <!--  -->
        </template>

        <template v-else>
          <VBtn
            v-if="step.attempts_left_total > 0 && step.attempts_left_today > 0"
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            :loading="isStarting"
            @click.prevent="start"
          >
            <VIcon left>
              play_arrow
            </VIcon>{{ $t('start.one') }}
          </VBtn>
        </template>

        <VBtn
          v-if="!step.is_required && !step.is_completed && !step.is_skipped"
          :large="$vuetify.breakpoint.lgAndUp"
          outlined
          :loading="isSkipping"
          @click.prevent="skip"
        >
          <VIcon left>
            skip_next
          </VIcon> {{ $t('skip.one') }}
        </VBtn>
      </VCardActions>
    </header>
    <VDivider />

    <div
      v-if="quiz.is_started"
      class="step__body"
    >
      <template v-if="quiz.is_completed">
        <VCardTitle tag="h2">
          {{ $t('questions_and_variants_of_answers.one') }}:
        </VCardTitle>

        <VCardText
          v-if="quiz.questions_count"
          class="step__results"
        >
          <VRow>
            <VCol
              v-for="(question, idx) in questions"
              :key="question.question_id"
              cols="12"
            >
              <TrainingQuizQuestion
                :key="question.question_id"
                :question="question"
                :index="idx"
                :user-answers="quiz.user_answers[question.question_id]"
                :is-readonly="true"
                :is-exercise="quiz.is_exercise"
                :is-show-correct-answer="quiz.settings.show_correct_answers"
                :is-accept-incorrect-answers="quiz.settings.accept_incorrect_answers"
              />
            </VCol>
          </VRow>
        </VCardText>
        <VAlert
          v-else
          dense
        >
          Вопросов почему-то нет,
          <RouterLink :to="{ name: 'messenger' }">
            обратитесь к&nbsp;администратору
          </RouterLink>.
        </VAlert>
      </template>

      <template v-else-if="permissions.can_write">
        <VCardTitle tag="h2">
          {{ $t('questions.one') }} {{ idxCurrentQuestion + 1 }} {{ $t('out_of.one') }} {{ quiz.questions_count }}
        </VCardTitle>

        <VCardText
          v-if="quiz.questions_count"
          :class="{'no-user-select': vendorCopyProtectionEnabled}"
        >
          <TrainingTimer
            v-if="quiz.settings.max_minutes_per_attempt"
            class="mb-4"
            :on="timerOn"
            :buffer-value="maxSecondsPerAttempt"
            :seconds="leftTime"
            @stop="stop"
          />

          <TrainingQuizQuestion
            v-if="currentQuestion"
            :key="currentQuestion.question_id"
            v-model="userAnswers"
            :question="currentQuestion"
            :index="idxCurrentQuestion"
            :user-answers="quiz.user_answers[currentQuestion.question_id]"
            :is-exercise="quiz.is_exercise"
            :is-show-correct-answer="quiz.settings.show_correct_answers"
            :is-accept-incorrect-answers="quiz.settings.accept_incorrect_answers"
          />
        </VCardText>

        <VAlert
          v-else
          dense
        >
          Вопросов почему-то нет,
          <RouterLink :to="{ name: 'messenger' }">
            обратитесь к&nbsp;администратору
          </RouterLink>.
        </VAlert>

        <VSpacer />

        <VCardActions
          v-if="!quiz.is_completed"
          class="actions"
        >
          <VBtn
            v-if="!isFirstQuestion"
            :large="$vuetify.breakpoint.lgAndUp"
            outlined
            @click.prevent="prev"
          >
            <VIcon left>
              navigate_before
            </VIcon> {{ $t('previous_question.one') }}
          </VBtn>
          <VBtn
            v-if="!isLastQuestion"
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            :loading="isSaving"
            @click.prevent="save"
          >
            {{ $t('next_question.one') }} <VIcon right>
              navigate_next
            </VIcon>
          </VBtn>
          <VBtn
            v-if="isLastQuestion"
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            :loading="isSaving"
            @click.prevent="save"
          >
            <VIcon left>
              done
            </VIcon> {{ $t('complete.one') }}
          </VBtn>
        </VCardActions>
      </template>
    </div>
  </VCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { stopQuiz, startQuiz, acceptQuiz, updateQuiz } from '@/api/api'
import trainingStep from '@/mixins/trainingStep'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import UPrintResults from '@components/UPrintResults.vue'
import StepToolbar from '@components/StepToolbar.vue'
import TrainingQuizQuestion from '@components/TrainingQuizQuestion.vue'

const MAX_GRADE_VALUE = 100

export default {
  name: 'TrainingQuiz',

  components: {
    StepToolbar,
    UPrintResults,
    TrainingQuizQuestion
  },

  mixins: [trainingStep],

  data () {
    return {
      idxCurrentQuestion: 0,
      isSaving: false,
      isStarting: false,
      userAnswers: [],
      dialog: false
    }
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR,
      quiz: getters.QUIZ,
      lesson: getters.LESSON,
      permissions: getters.PERMISSIONS,
      questions: getters.QUESTIONS
    }),

    vendorCopyProtectionEnabled () {
      return this.vendor?.branding?.copy_protection_enabled || false
    },

    isLastQuestion () {
      return this.idxCurrentQuestion === (this.quiz.questions_count - 1)
    },

    isFirstQuestion () {
      return this.idxCurrentQuestion === 0
    },

    currentQuestion () {
      if (this.quiz.questions_count) {
        return this.questions[this.idxCurrentQuestion]
      }

      return null
    },

    acceptableGrade () {
      if (this.quiz.settings.grade_ranges && this.quiz.settings.grade_ranges.length) {
        for (const gradeRange of this.quiz.settings.grade_ranges) {
          if (gradeRange.is_acceptable) {
            return gradeRange
          }
        }
      }

      return null
    }
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      createNotification: actions.CREATE_NOTIFICATION,
      updateStep: actions.UPDATE_STEP,
      saveQuestion: actions.SAVE_QUIZ_QUESTION
    }),

    reset () {
      this.idxCurrentQuestion = 0
      this.userAnswers = []
    },

    async start () {
      this.isStarting = true
      const data = new FormData()
      data.append('step', this.step.id)

      try {
        const response = await startQuiz(data)
        this.updateStep(response)
        this.go()
      } finally {
        this.isStarting = false
      }
    },

    async stop () {
      const data = new FormData()
      data.append('_confirm', 1)

      try {
        const response = await stopQuiz(this.quiz.id, data)
        this.updateStep(response)
        this.reset()
      } catch {}
    },

    async accept () {
      try {
        const data = new FormData()
        data.append('step', this.step.id)

        const response = await acceptQuiz(this.quiz.id, data)
        this.updateStep(response)
      } finally {
        this.dialog = false
      }
    },

    validate () {
      if (!this.currentQuestion) {
        throw new Error('Не удалось определить вопрос!')
      }

      if (!this.userAnswers.length) {
        throw new Error(this.$t('please_enter_answer.one'))
      }
    },

    packData () {
      const qid = this.currentQuestion.question_id
      const data = new FormData()
      data.append('question', qid)
      data.append('_replace', 1)
      this.userAnswers.forEach(answer => data.append(`user_answer[${qid}]`, answer))

      return data
    },

    async save () {
      this.isSaving = true

      try {
        this.validate()

        const opts = {
          quizId: this.quiz.id,
          data: this.packData()
        }

        // На последнем вопросе попытаемся обновить все данные теста, иначе сохраним текущий ответ на вопрос
        if (this.isLastQuestion) {
          const response = await updateQuiz(opts.quizId, opts.data)
          this.updateStep(response)
        } else {
          opts.questionId = this.currentQuestion.question_id
          opts.userAnswers = this.userAnswers
          await this.saveQuestion(opts)
        }

        if (this.quiz.is_completed) {
          this.reset()
          return
        }

        const incorrectAnswers = this.currentQuestion.grade < MAX_GRADE_VALUE
        if (this.quiz.is_exercise && !this.quiz.settings.accept_incorrect_answers && incorrectAnswers) {
          throw new Error('Выберите все верные варианты ответа!')
        }

        this.next()
      } catch (err) {
        this.createNotification({
          msg: err.message,
          type: 'error'
        })
      } finally {
        this.isSaving = false
      }
    },

    go (questionIdx = 0) {
      const maxVal = this.quiz.questions_count - 1
      const minVal = 0

      this.idxCurrentQuestion = Math.max(minVal, Math.min(maxVal, questionIdx))
    },

    prev () {
      const questionIdx = this.idxCurrentQuestion - 1
      this.go(questionIdx)
    },

    next () {
      const questionIdx = this.idxCurrentQuestion + 1
      this.go(questionIdx)
    },

    register () {
      if (this.quiz.questions_count) {
        if (this.quiz.is_started && !this.quiz.is_completed) {
          let idx = this.quiz.questions_answered

          if (this.quiz.is_exercise && !this.quiz.settings.accept_incorrect_answers) {
            idx = this.questions.findIndex(q => q.grade !== MAX_GRADE_VALUE)
          }

          this.go(idx)
        }
      }
    }
  }
}
</script>
